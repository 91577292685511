<template>
  <footer
    v-style="{
      'bg': backgroundColor,
      'c': footerTextColor,
      'min-h': { lg: '37rem' },
      'p': { sm: '1rem 1rem 9rem 1rem', lg: '1.5rem' },
    }"
    class="contain-content overflow-hidden lg:flex lg:between"
    style="contain-intrinsic-size: 100vw 600px"
  >
    <div v-style="{ w: { lg: '25.4375rem' } }" class="f-col shrink-0">
      <div class="grow">
        <vf-accordion
          v-for="({ title, children }, i) in columns"
          :key="i"
          size="sm"
          :title
          :value="title"
          variant="aligned"
        >
          <ul class="pb-4 space-y-1">
            <template v-for="({ title: name, target }) in children" :key="name">
              <li v-if="target">
                <vf-link
                  :to="target.url"
                  variant="quiet"
                  @click="$gtm.push('footer.onClickFooterLink', `${title} - ${name}`)"
                >
                  {{ name }}
                </vf-link>
              </li>
            </template>
          </ul>
        </vf-accordion>
      </div>
      <div>
        <div>
          <div class="mt-4 flex gap-2">
            <template v-for="({ to, color }, name) in data?.social" :key="name">
              <base-link
                v-if="to"
                :aria-label="name"
                class="duration @hactive:c-$ch"
                :style="`--ch: ${color}`"
                :to
              >
                <vf-icon :name="`${name}`" size="md" />
              </base-link>
            </template>
          </div>
          <div v-if="data?.storeIcons.length > 0" class="mb-6 flex gap-2">
            <base-link
              v-for="link in data?.storeIcons.filter(({ name }) => storeIcons[name])"
              :key="link.name"
              :to="link.to"
            >
              <base-picture v-bind="link" />
            </base-link>
          </div>
        </div>
        <base-picture v-if="images" v-bind="{ ...images, ...mediaSmall }" />
        <vf-video
          v-else-if="video"
          v-bind="{ ...video, ...mediaSmall }"
          autoplay
          :controls="false"
          loop
          muted
        />
        <vf-location-selector class="mb-6 text-sm">
          | {{ $t.changeCountry }}
        </vf-location-selector>
        <nav class="flex gap-2 text-sm wrap">
          <base-link v-for="({ title, to }) in data?.legal" :key="title" class="ws-nowrap underlined" :to>
            {{ title }}
          </base-link>
        </nav>
      </div>
    </div>
    <base-picture v-if="images" v-bind="{ ...images, ...mediaLarge }" :props-img="{ class: 'w-a' }" />
    <vf-video
      v-else-if="video"
      v-bind="{ ...video, ...mediaLarge }"
      autoplay
      :controls="false"
      loop
      muted
    />
  </footer>
</template>

<script lang="ts" setup>
const { getFooter, getMedia } = useCms()
const [
  { data },
  { backgroundColor, columns, footerTextColor, media, storeIcons = {} }
] = await Promise.all([
  useApi().internal.getFooter(),
  getFooter()
]) as any

const { images, video } = getMedia(media)

const mediaLarge = {
  class: 'ml-6 <lg:hidden',
  fit: 'contain',
  style: 'height: 37rem'
}

const mediaSmall = {
  class: 'my-4 w-full lg:hidden',
}
</script>
